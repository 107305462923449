<template>
  <li>
    <div
      class="input-group align-items-stretch"
      :class="{bold: isFolder}">
      <div class="d-flex align-items-center mr-2">
        <i class="fe fe-x" @click.prevent="$emit('remove-item', {index, parent})"></i>
      </div>

      <input
        v-model="item.name"
        type="text"
        class="form-control"
        :class="{'is-invalid': hasErrorResponse}"
        :placeholder="`Input sub-${entity} for ${parent.name}`"
        required>
      <div class="d-flex align-items-center ml-2">
        <span v-if="!isFolder" class="input-group-append">
          <a href="#" @click.prevent="makeFolder"><i class="fe fe-plus"></i> Add new level</a>
        </span>
      </div>
    </div>
    <small v-if="hasErrorResponse" class="pl-5 error text-danger">{{response.error.name.join(', ')}}</small>
    <ul v-if="isFolder">
      <category-tree
        v-for="(child, index) in item[subCategoryKey]"
        :key="index"
        class="item"
        :entity="entity"
        :sub-category-key="subCategoryKey"
        :item="child"
        :parent="item"
        :index="index"
        :response="response"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
        @remove-item="$emit('remove-item', $event)"
      ></category-tree>
      <li class="pl-5">
        <a
          v-if="isFolder"
          href="#"
          @click.prevent="$emit('add-item', item)"><i class="fe fe-plus"></i> Add {{entity}} for {{item.name}}</a>
      </li>
    </ul>
  </li>
</template>

<script>

export default {
  name: 'CategoryTree',
  props: {

    entity: {
      default: 'category',
      type: String,
    },
    subCategoryKey: {
      default: 'sub_categories',
      type: String,
    },
    index: Number,
    item: Object,
    parent: Object,
    response: Object,
  },

  computed: {

    hasErrorResponse() {
      return this.response.error?.name &&
                this.response.data.index === this.index &&
                this.response.data.parent_id === this.parent.id;
    },
    isFolder() {
      return this.item[this.subCategoryKey]?.length;
    },
  },
  methods: {

    makeFolder() {
      if (!this.isFolder) {
        this.$emit('make-folder', this.item);
      }
    },
  },
};

</script>

<style scoped>

    ul li {
        list-style: none;
        padding: 0.5rem 0;
    }

</style>
